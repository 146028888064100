import React from "react";
import PropTypes from "prop-types";
import { getDayValue } from "../utils/utils";
import "./tag-filters.sass";
import cross from "../img/cross.svg";

const TagFilters = ({ tags, activeTags, setActiveTag }) => {
  const handleTags = (tag) => {
    if (activeTags.includes(tag)) {
      const newTags = activeTags.filter((activeTag) => activeTag !== tag);
      setActiveTag(newTags);
    } else {
      const newTags = [...activeTags, tag];
      newTags.sort((a, b) => getDayValue(a) - getDayValue(b));
      setActiveTag(newTags);
    }
  }

  return (
    <div className="tag-filters">
      {tags.map((tag, index) => {
        return (
          <button
            key={index}
            className={`tag-filter ${activeTags.includes(tag) ? "active" : ""}`}
            onClick={() => handleTags(tag)}
          >
            <img src={cross} alt="cross" className="cross" />
            {tag}
          </button>
        );
      })}
    </div>
  );
}

TagFilters.propTypes = {
  tags: PropTypes.array,
  activeTags: PropTypes.array,
  setActiveTag: PropTypes.func,
}


export default TagFilters;