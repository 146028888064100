import React from "react";
import PropTypes from "prop-types";
import { parseNumberToHour } from "../utils/utils";
import "./schedule-calendar-day.sass"
import { BsDot } from "react-icons/bs";
import { isMobile } from "react-device-detect";
import { Link } from "gatsby";

const ScheduleCalendarDay = ({ day, classes }) => {
  const numberOfGrid = [...Array(14).keys()]
  return (
    <div className={`schedule-calendar-day`}>
      <div className="schedule-day-overlay">
        {numberOfGrid.map((gridItem, index) => {
          return (
            <div className="schedule-day-overlay-item" key={index}></div>
          );
        })}
      </div>
      <p className="schedule-calendar-day-title">{day}</p>
      {classes.map((classItem, index) => {
        const { title, start, duration, instructor, slug } = classItem;
        return (
          <Link to={slug} className="schedule-calendar-day-item" style={{
            gridColumn: `${(start * 2) + 1 - 16} / span ${duration * 2}`,
          }}>
            <p className="schedule-calendar-day-item-title">{title}</p>
            <div className="schedule-calendar-day-item-description">
              <p>{`${parseNumberToHour(start)} - ${parseNumberToHour(start + duration)}`}</p>
              {isMobile ? <BsDot /> : null}
              <p>{instructor}</p>
            </div>
          </Link>
        );
      })}
    </div>
  );
}

ScheduleCalendarDay.propTypes = {
  day: PropTypes.string,
  classes: PropTypes.array,
}


export default ScheduleCalendarDay;