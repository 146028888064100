import React from "react";
import RedirectHeader from "../components/RedirectHeader";
import Layout from "../components/Layout";
import TagFilters from "../components/TagFilters";
import ScheduleCalendarDay from "../components/ScheduleCalendarDay";
import { parseClassesToCalendar, parseDayName } from "../utils/utils";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { BsFileEarmark } from "react-icons/bs";
import { TfiDownload } from "react-icons/tfi";
import { isMobile } from "react-device-detect";
import "./schedule.sass";

const SchedulePage = ({ data }) => {

  const { title } = data.markdownRemark.frontmatter;
  const allTags = ["PON", "WT", "ŚR", "CZW", "PT", "SOB"]
  const [activeTags, setActiveTags] = React.useState(allTags);
  const columns = ["DZIEŃ", "9:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00"];
  const parsedClasses = parseClassesToCalendar(data.allMarkdownRemark.edges.map((edge) => {
    return {
      ...edge.node.frontmatter,
      slug: edge.node.fields.slug
    }
  }));
  const numberOfGrid = [...Array(14).keys()]

  return (
    <Layout>
      <div className="section-container">
        <section className="section">
          <RedirectHeader title="CENY & TERMINY" showRedirect={false} />
          <h1 style={{
            fontWeight: 400,
            marginTop: "0",
          }}>{title}</h1>
        </section>
      </div>
      <div className="section-container">
        <section className="section">
          <div className="schedule-container">
            <div className="prices-header">
              <h5 className="h5-black">PLAN ZAJĘĆ</h5>
              <div className="download-container" style={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
                fontSize: "1.25rem"
              }}>
                <BsFileEarmark style={{
                  color: "gray",
                }} />
                PDF
                <TfiDownload />
              </div>
            </div>
            <div className="schedule-calendar-container">
              <TagFilters tags={allTags} activeTags={activeTags} setActiveTag={setActiveTags} />
            </div>
            <div className="schedule-header-container">
              <div className="schedule-header">
                <div className="schedule-day-overlay">
                  {numberOfGrid.map((gridItem, index) => {
                    return (
                      <div className="schedule-day-overlay-item" key={index}></div>
                    );
                  })}
                </div>
                {
                  columns.map((column, index) => {
                    return (
                      <div className="schedule-item" key={index}>
                        {column}
                      </div>
                    );
                  }
                  )
                }
              </div>
            </div>
            <div className="schedule-body">
              {
                activeTags.map((tag, index) => (
                  <ScheduleCalendarDay day={isMobile ? parseDayName(tag) : tag} classes={parsedClasses.filter(item => item.eventDay === parseDayName(tag))} />
                )
                )
              }
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

SchedulePage.propTypes = {
  data: PropTypes.object
}

export default SchedulePage;

export const pageQuery = graphql`
  query SchedulePageQuery {
    markdownRemark(frontmatter: {templateKey: {eq: "schedule-page"}}) {
      frontmatter {
        title
      }
    }
    allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "class"}}}) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            instructor
            eventDates {
              eventDay
              start
              duration
            }
          }
        }
      }
    }
  }
`


